import React from 'react';

import '../styles/05-components/emailForm.css';

export default props => 
    <div id="mc_embed_signup">
        <form 
            action="https://gmail.us5.list-manage.com/subscribe/post?u=7b7cbe1254d0aa08f05aa1143&amp;id=178f6fa837" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            className="validate" 
            target="_blank" 
            noValidate
        >
            <div id="mc_embed_signup_scroll">
                <label htmlFor="mce-EMAIL" className="sr-only">Enter Email Address</label>
                <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="enter email address" required />
                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                <div className="hidden-form" aria-hidden="true">
                    <input type="text" name="b_7b7cbe1254d0aa08f05aa1143_178f6fa837" tabIndex="-1" value="" readOnly/>
                </div>
                <input type="submit" value=">" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
        </form>
    </div>
