import React, { Component } from 'react';
import twitterIcon from '../assets/icons/WBB_Social_TW.svg';
import facebookIcon from '../assets/icons/WBB_Social_FB.svg';
import instagramIcon from '../assets/icons/WBB_Social_Insta.svg';
import twitterIconHover from '../assets/icons/WBB_Social_TW_Hover.svg';
import facebookIconHover from '../assets/icons/WBB_Social_FB_Hover.svg';
import instagramIconHover from '../assets/icons/WBB_Social_Insta_Hover.svg';

import '../styles/05-components/socialNav.css'

class SocialNav extends Component {
    constructor(props) {
        super(props)

        this.state = {
            twitterSrc: twitterIcon,
            facebookSrc: facebookIcon,
            instagramSrc: instagramIcon
        }

        this.mouseOver = this.mouseOver.bind(this)
        this.mouseOut = this.mouseOut.bind(this)
    }

    mouseOver(e) {
        if (e.target.id === 'twitter') {
            this.setState(
                {
                    twitterSrc: twitterIconHover
                }
            )
        } else if (e.target.id === 'facebook') {
            this.setState(
                {
                    facebookSrc: facebookIconHover
                }
            )
        } else if (e.target.id === 'instagram') {
            this.setState(
                {
                    instagramSrc: instagramIconHover
                }
            )
        }
    }

    mouseOut(e) {
        if (e.target.id === 'twitter') {
            this.setState(
                {
                    twitterSrc: twitterIcon
                }
            )
        } else if (e.target.id === 'facebook') {
            this.setState(
                {
                    facebookSrc: facebookIcon
                }
            )
        } else if (e.target.id === 'instagram') {
            this.setState(
                {
                    instagramSrc: instagramIcon
                }
            )
        }
    }

    render() {
        return (
            <nav className="weco-social">
                <h3 className="sr-only">Weco Bottle and Biergarten Social Media</h3>
                <ul className="weco-social__list">
                    <li className="weco-social__item">
                        <a
                            href="https://www.facebook.com/WECO-Bottle-Biergarten-108086857271642/"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="facebookLink"
                        >
                            <img
                                id="facebook"
                                className="weco-social__icon"
                                src={this.state.facebookSrc}
                                onMouseOver={this.mouseOver}
                                onMouseOut={this.mouseOut}
                                alt="Weco Bottle and Biergarten on Facebook"
                            />
                        </a>
                    </li>
                    <li className="weco-social__item">
                        <a
                            href="https://twitter.com/WecoBiergarten"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="twitterLink"
                        >
                            <img
                                id="twitter"
                                className="weco-social__icon"
                                src={this.state.twitterSrc}
                                onMouseOver={this.mouseOver}
                                onMouseOut={this.mouseOut}
                                alt="Weco Bottle and Biergarten on Twitter"
                            />
                        </a>
                    </li>
                    <li className="weco-social__item">
                        <a
                            href="https://www.instagram.com/wecobeer/"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="instagramLink"
                        >
                            <img
                                id="instagram"
                                className="weco-social__icon"
                                src={this.state.instagramSrc}
                                onMouseOver={this.mouseOver}
                                onMouseOut={this.mouseOut}
                                alt="Weco Bottle and Biergarten on Instagram"
                            />
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default SocialNav