import React from 'react';
import LogoLink from './LogoLink';
import TitleBanner from './TitleBanner';
import logo from '../assets/logos/WBB_LogoPrimary_1C_Dark.svg'

import '../styles/05-components/header.css'

export default props => 
    <header className="weco-header">
        <h1 className="sr-only">WECO Bottle and Biergarten</h1>
        <LogoLink
            url="\"
            imageUrl={logo} 
            imageAltText="WECO Bottle and Biergarten Logo"></LogoLink>
        <TitleBanner title="Retail Craft Beer & Wine + Biergarten"></TitleBanner>
    </header>