import React, { Component } from 'react'
import '../../styles/05-components/faqs.css';
import Loading from '../Loading';

export default class FAQs extends Component {
    constructor() {
        super();
        this.state = {
            faqs: [],
            loading: true
        }
    }

    componentDidMount() {
        let dataURL = "https://www.wecobeer.com/wp-json/wp/v2/faq/?_embed";
        fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    faqs: res[0].acf.faqs,
                    loading: false
                })
            })
    }

    render() {
        return (
            <section className="weco-faqs-section contain-extreme">
                <h1 className="weco-faqs__heading">FAQs</h1>
                {this.state.loading ?
                    <Loading></Loading>
                    : <ul className="weco-faqs__list">
                        {
                            this.state.faqs ? this.state.faqs.map((faq, index) => {
                                return (
                                    <li key={`faq-${index}`} className="weco-faqs__item">
                                        <h2 className="weco-faqs__question">{faq.question}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: faq.answer }} className="weco-faqs__answer"></div>
                                    </li>
                                )
                            }) : null
                        }
                    </ul>
                }
            </section>
        );
    }
}

