import React from 'react'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import '../styles/05-components/menu.css'

export default props =>
    <nav className='weco-menu'>
        <ul className='weco-menu__list'>
            <li className='weco-menu__item'>
                <HashLink to="/" className='weco-menu__link'>Home</HashLink>
            </li>
            <li className='weco-menu__item'>
                <NavHashLink to="/faqs" exact={true} className='weco-menu__link'>FAQs</NavHashLink>
            </li>
            <li className='weco-menu__item'>
                <NavHashLink to="/food-trucks" exact={true} className='weco-menu__link'>Food Trucks</NavHashLink>
            </li>
            <li className='weco-menu__item'>
                <NavHashLink smooth to='/#onTap' className='weco-menu__link'>On Tap</NavHashLink>
            </li>
        </ul>
    </nav>
