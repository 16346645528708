import React from 'react';

import '../styles/05-components/footerInfo.css';

export default props =>
    <div className="weco-info">
        <section className="weco-info__section">
            <h3 className="sr-only">Address</h3>
            <p className="weco-info__text">626 Meeting St</p>
            <p className="weco-info__text">West Columbia, SC</p>
        </section>
        <section className="weco-info__section">
            <h3 className="sr-only">Hours</h3>
            <p className="weco-info__text">Mon - Sun</p>
            <p className="weco-info__text">11am - 10pm</p>
        </section>
        <section className="weco-info__section">
            <h3 className="sr-only">Contact</h3>
            <a className="weco-info__link" href="tel:+18038511279" id="phoneLink">803.851.1279</a>
            <a className="weco-info__link" href="mailto:wecobeer@gmail.com" id="emailLink">wecobeer@gmail.com</a>
        </section>
    </div>