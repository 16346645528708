import React, { Component } from 'react'
import '../../styles/05-components/eventsSection.css';
import Loading from '../Loading';

export default class Events extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            loading: true
        }
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);
    }

    componentDidMount() {
        let dataURL = "https://www.wecobeer.com/wp-json/wp/v2/events/?per_page=100&_embed";
        fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    events: this._filterAndSortEvents(res),
                    loading: false
                })
            })
    }

    _filterAndSortEvents(events) {
        let results = [...events];

        results.forEach(event => {
            event.acf.event_date = new Date(event.acf.event_date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3'));
            let hour = event.acf.start_time.substr(0, 2);
            event.acf.event_date.setHours(hour);
        });

        results = results.filter((event) => {
            return event.acf.event_date >= this.today
        }).slice().sort((a, b) => a.acf.event_date - b.acf.event_date);

        return results;
    }

    _toDate(dStr) {
        var now = new Date();
        now.setHours(dStr.substr(0, dStr.indexOf(":")));
        now.setMinutes(dStr.substr(dStr.indexOf(":") + 1, 2));
        now.setSeconds(0);
        return now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    }

    _convertDay(day) {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[day];
    }

    render() {
        return (
            <section className="weco-events-section contain-extreme">
                {this.state.loading ?
                    <Loading></Loading>
                    : <div>
                        <h1 className="weco-events__heading">Food Trucks and Events</h1>
                        <ul className='weco-events__events'>
                            {
                                this.state.events ? this.state.events.map((event, index) => {
                                    return (
                                        <li id={event.id} key={`event-${index}`} className='weco-events__event'>
                                            <div className='weco-event__main-info'>
                                                {event.acf.image ?
                                                    <div className="weco-event__image">
                                                        {event.acf.image ? <img src={event.acf.image} alt="alt" className="weco-event__image" /> : null}
                                                    </div> : null
                                                }
                                                <header className="weco-event__header">
                                                    <h2 className="weco-event__name">{event.title.rendered}</h2>
                                                    <p className='weco-event__day'>{this._convertDay(event.acf.event_date.getDay())}</p>
                                                    <p className="weco-event__date">{event.acf.event_date.toLocaleDateString()}</p>
                                                    <p className="weco-event__time">{`${this._toDate(event.acf.start_time)}`} - {`${this._toDate(event.acf.end_time)}`}</p>
                                                </header>
                                            </div>
                                            {event.acf.description ?
                                                <div className="weco-event__description">
                                                    {event.acf.description ? <div dangerouslySetInnerHTML={{ __html: event.acf.description }} className="weco-event__description"></div> : null}
                                                </div> : null
                                            }
                                            {event.acf.link ? <p><a href={event.acf.link} target='_blank' rel="noopener noreferrer" className="weco-event__link">{event.acf.link_display_text}</a></p> : null}
                                            {index !== (this.state.events.length - 1) ? <div className="weco-event__divider"></div> : null}
                                        </li>
                                    )
                                }) : null
                            }
                        </ul>
                    </div>
                }
            </section>
        )
    }
}

