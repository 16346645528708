import React from "react"

import '../styles/05-components/logoLink.css'

export default props => {
  return (
    <a className="weco-logo-link" href={props.url}>
      <img 
        className="weco-logo-link__image"
        src={props.imageUrl}
        alt={props.imageAltText} />
    </a>
  )
}