import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import './App.css';
import FaqsPage from './pages/Faqs';
import EventsPage from './pages/Events';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';

export function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash)
      window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Menu />
        <div className="weco-page">
          <Header />
          <main className="weco-page__content">
            <Switch>
              <Route path={["/events", "/food-trucks"]}>
                <EventsPage />
              </Route>
              <Route path="/faqs">
                <FaqsPage />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
}
