import React from 'react';

import '../styles/05-components/titleBanner.css'

export default props => 
    <section className="weco-title-banner">
        <div className="weco-title-banner__container contain">
            <h2 className="weco-title-banner__heading">{props.title}</h2>
        </div>
    </section>
