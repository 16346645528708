import React from 'react';
import LogoLink from './LogoLink';
import GeneralInfo from './FooterInfo';
import SocialNav from './SocialNav';
import EmailForm from './EmailForm';
import logo from '../assets/logos/WBB_LogoAlt_Wide_2C_OrngWht.svg'

import '../styles/05-components/footer.css'

export default props => 
    <footer className="weco-footer">
        <div className="weco-footer__container contain">
            <section className="weco-footer__info">
                <h2 className="sr-only">WECO Bottle and Biergarten General Info</h2>
                <LogoLink
                    url="\"
                    imageUrl={logo} 
                    imageAltText="Weco Bottle and Biergarten Footer Logo"></LogoLink>
                <GeneralInfo></GeneralInfo>
            </section>
            <section className="weco-footer__connect">
                <div className="center-text">
                    <h2 className="weco-footer__heading">Stay Updated</h2>
                    <EmailForm/>
                    <SocialNav/>
                </div>
            </section>
        </div>
    </footer>