import React from 'react'
import PrimaryInfo from '../components/contentSections/PrimarySection';
import OnTap from '../components/contentSections/TapsSection';
import ThisWeeksEvents from '../components/contentSections/ThisWeekSection';


export default function Home(props) {
  return (
    <div>
      <PrimaryInfo></PrimaryInfo>
      <ThisWeeksEvents></ThisWeeksEvents>
      <OnTap></OnTap>
    </div>
  );
}
