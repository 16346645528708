import React from 'react'
import { useEffect } from 'react';

import '../../styles/05-components/tapsSection.css';

export default function TapsSection(props) {
    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.text = '!function(e,n){var t=document.createElement("script"),a=document.getElementsByTagName("script")[0];t.async=1,a.parentNode.insertBefore(t,a),t.onload=t.onreadystatechange=function(e,a){(a||!t.readyState||/loaded|complete/.test(t.readyState))&&(t.onload=t.onreadystatechange=null,t=void 0,a||n&&n())},t.src=e}("https://embed-menu-preloader.untappdapi.com/embed-menu-preloader.min.js",function(){PreloadEmbedMenu("menu-container",30113,115982)});';
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    return (
        <section id="onTap" className="weco-taps-section contain">
            <h1>On Tap</h1>
            <div id="menu-container" className="untappd-container contain"></div>
        </section>
    )
}