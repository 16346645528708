import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../../styles/05-components/thisWeek.css';
import Loading from '../Loading';

export default class ThisWeeksEvents extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            loading: true
        }
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);
        this.sevenDaysFromToday = new Date();
        this.sevenDaysFromToday = new Date(this.sevenDaysFromToday.setDate(this.sevenDaysFromToday.getDate() + 7));
    }

    componentDidMount() {
        let dataURL = "https://www.wecobeer.com/wp-json/wp/v2/events/?per_page=100&_embed";
        fetch(dataURL)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    eventsByDate: this._filterAndSortEvents(res),
                    loading: false
                })
                console.log(res)
            })
    }

    _filterAndSortEvents(events) {
        let results = [...events];

        results.forEach(event => {
            event.acf.event_date = new Date(event.acf.event_date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1/$2/$3'));
        });

        results = results.filter((event) => {
            return event.acf.event_date >= this.today && event.acf.event_date <= this.sevenDaysFromToday
        }).slice().sort((a, b) => a.acf.event_date - b.acf.event_date);

        results.forEach(event => {
            let hour = event.acf.start_time.substr(0, 2);
            event.acf.event_date.setHours(hour);
        });

        // this gives an object with dates as keys
        const groups = results.reduce((groups, event) => {
            const date = event.acf.event_date.toISOString().split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(event);
            return groups;
        }, {});

        results = Object.keys(groups).map((date) => {
            return {
                date,
                events: groups[date]
            };
        });

        return results;
    }

    _processTime(dStr) {
        var now = new Date();
        now.setHours(dStr.substr(0, dStr.indexOf(":")));
        now.setMinutes(dStr.substr(dStr.indexOf(":") + 1, 2));
        now.setSeconds(0);
        return now.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    }

    _convertDay(day) {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[day];
    }

    render() {
        return (
            <section className="weco-this-week weco-primary-section contain">
                <div>
                    {this.state.loading ?
                        <Loading></Loading>
                        : <div>
                            <h1>This Week Food Trucks & Events</h1>
                            <h2 className='weco-this-week__dates'>{this.today.toLocaleDateString()} - {this.sevenDaysFromToday.toLocaleDateString()}</h2>
                            <p className='weco-this-week__all-events'><Link to="/events">View All Events</Link></p>
                            <ul className='weco-this-week__event-groups'>
                                {
                                    this.state.eventsByDate ? this.state.eventsByDate.map((eventGroup, index) => {
                                        const groupDate = new Date(eventGroup.date.replace(/-/g, '/'));
                                        return (
                                            <li id={eventGroup.date} key={`eventGroup-${eventGroup.date}-${index}`} className='weco-this-week__event-group'>
                                                <div className='weco-this-week__date-info'>
                                                    <p className='weco-this-week__day'>{this._convertDay(groupDate.getDay())}</p>
                                                    <p className='weco-this-week__date'>{groupDate.toLocaleDateString()}</p>
                                                    <ul className='weco-this-week__events'>
                                                        {
                                                            eventGroup.events.map((event, index) => {
                                                                return (
                                                                    <li key={`event-${index}`} className='weco-this-week__event'>
                                                                        {/* <p className="weco-this-week__date">{event.acf.event_date.toLocaleDateString()}</p> */}
                                                                        <div>
                                                                            <h3 className="weco-this-week__name">{event.title.rendered}</h3>
                                                                            <p className="weco-this-week__time">{`${this._processTime(event.acf.start_time)}`} {event.acf.end_time ? <span>- {`${this._processTime(event.acf.end_time)}`}</span> : null}</p>
                                                                        </div>
                                                                        <p className='weco-this-week__event-link'>{event.acf.link ? <a href={event.acf.link} target='_blank' rel="noopener noreferrer" className="weco-this-week__link">{event.acf.link_display_text}</a> : null}</p>
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    }) : null
                                }
                            </ul>
                        </div>
                    }
                </div>
            </section>
        )
    }
}

